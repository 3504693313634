export const hasura_endpoint = "hasura.trytweetlio.click/v1/graphql";
export const extension_link =
  "https://chromewebstore.google.com/detail/tweetlio-x-twitter-compan/fepmhecpcjcednhfffhoehebeaoakjbh";
export const app_url = "https://app.tweetlio.com/";

export const client_id = "OVJ5UWJpUHE3SlF0a1FPZTc0ZGk6MTpjaQ";
// export const redirect_url = "http://localhost:3000/callback";
export const redirect_url = "https://app.tweetlio.com/callback";
// export const backend_url = "http://localhost:4004";
// export const backend_url = "https://api-2ij2rpcskq-uc.a.run.app/v1";
export const backend_url =
  "https://vexrp0zl3d.execute-api.us-east-1.amazonaws.com/prod";

export const twitter_scope = [
  "tweet.read",
  "tweet.write",
  "users.read",
  "follows.read",
  "follows.write",
  "bookmark.read",
  "like.write",
  "like.read",
  "offline.access",
];
